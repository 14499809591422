<template>
  <div class="pages">
    <div
      v-if="loading"
      class="spinner__container d-flex align-center justify-center"
    >
      <v-card :width="600" :max-width="400" :elevation="0">
        <v-card-text class="d-flex justify-center">
          <v-progress-circular indeterminate />
        </v-card-text>
      </v-card>
    </div>
    <div
      v-else-if="!loading && pageFetchingError"
      style="width: fit-content"
      class="mx-auto pb-15"
    >
      <Error />
    </div>
    <recursion
      v-else-if="!pageFetchingError && content"
      :content="content"
      :componentName="
        content.props.component
          ? content.props.component
          : content.componentName
      "
    />
    <div v-else class="pa-5"> На странице нет контента </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Error from '@/view/pages/error/Error-1.vue'
const { mapState, mapActions } = createNamespacedHelpers('pages')
const { mapState: State_auth } = createNamespacedHelpers('auth')
export default {
  name: 'HomeSecond',
  metaInfo() {
    if (this.currentPage) {
      const { seo_title, seo_description, seo_key, photo } = this.currentPage
      const capitalizeTitle =
        seo_title.substring(0, 1).toUpperCase() + seo_title.substring(1)
      return {
        title: capitalizeTitle,
        titleTemplate: 'Store | %s',
        meta: [
          { name: seo_description, content: seo_key },
          { property: 'og:locale', content: this.localeLang },
          { property: 'og:title', content: seo_title },
          { property: 'og:description', content: seo_description },
          { property: 'og:url', content: this.$route.fullPath },
          { property: 'og:site_name', content: 'Tumar Art Group' },
          { property: 'og:image', content: photo },
          { property: 'og:image:secure_url', content: photo },
        ],
      }
    }
  },
  components: {
    Error,
  },
  data() {
    return {
      pageFetchingError: false,
      loading: true,
    }
  },
  computed: {
    ...mapState(['pages', 'currentPage']),
    ...State_auth(['localeLang']),
    content() {
      return this.currentPage && this.currentPage.content.content[0]
    },
  },
  methods: {
    ...mapActions(['fetchPageByUrl']),
  },
  async created() {
    try {
      await this.fetchPageByUrl('home')
    } catch {
      this.pageFetchingError = true
    }
    this.loading = false
  },
}
</script>

<style scoped>
.pages {
  color: #666;
}
.spinner__container {
  height: 500px;
}
</style>
